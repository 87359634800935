import { makeVar } from '@apollo/client'

import { AppEnvEnum } from '~/core/constants/globalTypes'

interface EnvGlobal {
  appEnv: AppEnvEnum
  apiUrl: string
  appVersion: string
  iamApi: string
  iamClient: string
}

export const envGlobalVar = makeVar<EnvGlobal>({
  appEnv: window.APP_ENV || APP_ENV,
  apiUrl: window.API_URL || API_URL,
  appVersion: APP_VERSION,
  iamApi: window.IAM_API || IAM_API,
  iamClient: window.IAM_CLIENT || IAM_CLIENT
})
