import { authRoutes } from './AuthRoutes'
import { customerRoutes } from './CustomerRoutes'
import { objectCreationRoutes, objectDetailsRoutes, objectListRoutes } from './ObjectsRoutes'
import { settingRoutes } from './SettingRoutes'
import { CustomRouteObject } from './types'
import { lazyLoad } from './utils'

export * from './types'
export * from './AuthRoutes'
export * from './CustomerRoutes'
export * from './ObjectsRoutes'
export * from './SettingRoutes'


// ----------- Layouts -----------
const SideNavLayout = lazyLoad(
  () => import(/* webpackChunkName: 'side-nav-layout' */ '~/layouts/SideNavLayout'),
)

// ----------- Pages -----------
const Error404 = lazyLoad(() => import(/* webpackChunkName: 'error-404' */ '~/pages/Error404'))

export const HOME_ROUTE = '/'
export const ERROR_404_ROUTE = '/404'

export const routes: CustomRouteObject[] = [
  {
    path: '*',
    element: <Error404 />,
  },
  {
    path: ERROR_404_ROUTE,
    element: <Error404 />,
  },
  {
    element: <SideNavLayout />,
    private: true,
    children: [
      ...customerRoutes,
      ...objectListRoutes,
      ...objectDetailsRoutes
    ],
  },
  ...authRoutes,
  ...objectCreationRoutes,
  ...settingRoutes
]
