import { CustomRouteObject } from './types'
import { lazyLoad } from './utils'

// ----------- Pages -----------
const Login = lazyLoad(() => import(/* webpackChunkName: 'login' */ '~/pages/Login'))

// ----------- Routes -----------
export const LOGIN_ROUTE = '/login'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'

export const authRoutes: CustomRouteObject[] = [
  {
    path: LOGIN_ROUTE,
    element: <Login />,
    onlyPublic: true,
  }
]
