import { CustomRouteObject } from './types'
import { lazyLoad } from './utils'

// ----------- Layouts -----------
const Settings = lazyLoad(() => import(/* webpackChunkName: 'settings' */ '~/layouts/Settings'))

// ----------- Pages -----------
const OrganizationInformations = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'organization-informations' */ '~/pages/settings/OrganizationInformations'
    ),
)
const Integrations = lazyLoad(
  () => import(/* webpackChunkName: 'integrations' */ '~/pages/settings/Integrations')
)

const Webhooks = lazyLoad(
  () => import(/* webpackChunkName: 'webhook' */ '~/pages/settings/Webhooks')
)
const WebhookLogs = lazyLoad(
  () => import(/* webpackChunkName: 'webhook-logs' */ '~/pages/settings/WebhookLogs')
)

// ----------- Routes -----------
export const SETTINGS_ROUTE = '/settings'
export const INVOICE_SETTINGS_ROUTE = `${SETTINGS_ROUTE}/invoice`
export const TAXES_SETTINGS_ROUTE = `${SETTINGS_ROUTE}/taxes`
export const ORGANIZATION_INFORMATIONS_ROUTE = `${SETTINGS_ROUTE}/organization-informations`
export const INTEGRATIONS_ROUTE = `${SETTINGS_ROUTE}/integrations`
export const PAYFABRIC_INTEGRATION_ROUTE = `${SETTINGS_ROUTE}/integrations/payfabric`
export const MEMBERS_ROUTE = `${SETTINGS_ROUTE}/members`
export const EMAILS_SETTINGS_ROUTE = `${SETTINGS_ROUTE}/emails`

export const WEBHOOK_ROUTE = `${SETTINGS_ROUTE}/webhook`
export const WEBHOOK_LOGS_ROUTE = `${SETTINGS_ROUTE}/webhook/:webhookId/logs`
export const WEBHOOK_LOGS_TAB_ROUTE = `${SETTINGS_ROUTE}/webhooks/:webhookId/logs/:tab`

export const settingRoutes: CustomRouteObject[] = [
  {
    private: true,
    element: <Settings />,
    children: [
      {
        path: [SETTINGS_ROUTE, ORGANIZATION_INFORMATIONS_ROUTE],
        private: true,
        element: <OrganizationInformations />,
      },
      {
        path: INTEGRATIONS_ROUTE,
        private: true,
        element: <Integrations />,
      },
      {
        path: WEBHOOK_ROUTE,
        private: true,
        element: <Webhooks />,
      },
    ],
  },
  {
    private: true,
    path: [WEBHOOK_LOGS_ROUTE, WEBHOOK_LOGS_TAB_ROUTE],
    element: <WebhookLogs />,
  },
]
